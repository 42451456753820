<template>
  <div class="customSetting">
    <el-row :gutter="20" type="flex">
      <el-col :span="6">
        <el-card class="headerNav">
          <div slot="header" class="clearfix">
            <span class="title">自定义试——考试设置</span>
          </div>
          <div class="setting">
            <div
              v-for="(item, index) in headerData"
              :key="item"
              class="setttingItem"
              :class="{ active: currentIndex == index }"
              @click="changeNav(index)"
            >
              {{ item }}
              <div class="space"></div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card v-if="currentIndex == 0" class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">试卷自定义设置</span>
          </div>
          <div>
            <el-form ref="form" :model="form" label-width="150px">
              <el-form-item label="当前试题数：" prop="collegeName">
                <el-input v-model="form.collegeName" class="input-width" clearable></el-input>
                个
                <div class="templateBtn">
                  <el-button type="primary" size="medium">模板导入</el-button>
                  <el-button size="medium">下载模板</el-button>
                </div>
              </el-form-item>
              <el-form-item prop="collegeIntroduce">
                <span slot="label">
                  <span class="span-box">
                    <span>试卷随机方式</span>
                    <i class="el-icon-question"></i>:
                  </span>
                </span>
                <el-radio-group v-model="form.collegeIntroduce">
                  <el-radio :label="true">固定顺序</el-radio>
                  <el-radio :label="false">随机顺序</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="分数设置：" prop="num">
                <div>
                  <span>共 50 题</span>
                  <span>总分 100 分</span>
                </div>
                <div class="pass">
                  <span class="passLine">设置及格线</span>
                  <el-input v-model="form.num" class="input-width" clearable></el-input>分
                </div>
              </el-form-item>
              <div class="keepBtn">
                <el-button type="primary" size="medium" @click="handleSubmit('form')">
                  保存
                </el-button>
              </div>
            </el-form>
          </div>
        </el-card>
        <el-card v-if="currentIndex !== 0" class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">考试前填写信息</span>
          </div>
          <div class="writeNews">
            <div class="header">
              <div>需要填写信息</div>
              <div class="custom"><i class="el-icon-plus"></i>自定义</div>
            </div>
            <el-checkbox-group v-model="checkList" class="checkList">
              <div><el-checkbox label="复选框 A"></el-checkbox></div>
              <div><el-checkbox label="复选框 B"></el-checkbox></div>
              <div><el-checkbox label="复选框 C"></el-checkbox></div>
            </el-checkbox-group>
          </div>
        </el-card>
        <testTime v-if="currentIndex !== 0" :test-type="1"></testTime>
        <!-- <el-card v-if="currentIndex !== 0">
          <div slot="header" class="clearfix">
            <span class="title">考试时间控制</span>
          </div>
          <div>
            <el-checkbox-group
              v-model="checkTimeList"
              class="testTime"
              @change="handleCheckTimeChange"
            >
              <div class="checkItem">
                <div><el-checkbox label="考试限时"></el-checkbox></div>
                <div>
                  <el-input
                    v-model="form.second"
                    class="input-width"
                    clearable
                    :disabled="timeData[0].status"
                  ></el-input>
                </div>
                <div class="icon">秒<i class="el-icon-caret-bottom"></i></div>
              </div>
              <div class="text">*超时自动交卷</div>
              <div class="checkItem">
                <div><el-checkbox label="开始时间"></el-checkbox></div>
                <el-date-picker
                  v-model="form"
                  type="datetime"
                  placeholder="选择日期时间"
                  class="date"
                  :disabled="timeData[1].status"
                >
                </el-date-picker>
              </div>
              <div class="checkItem">
                <div><el-checkbox label="结束时间"></el-checkbox></div>
                <el-date-picker
                  v-model="form"
                  type="datetime"
                  placeholder="选择日期时间"
                  class="date"
                  :disabled="timeData[2].status"
                >
                </el-date-picker>
              </div>
              <div class="checkItem">
                <div><el-checkbox label="单体限制"></el-checkbox></div>
                <div>
                  <el-input
                    v-model="form.minute"
                    class="input-width"
                    clearable
                    :disabled="timeData[3].status"
                  ></el-input>
                </div>
                <div class="icon">分钟</div>
              </div>
              <div class="text">
                设置后没题作答时间将被限制，超过时间将会自动下一题，答过的试题不能返回修改。
                如果填写0秒，答题单个不做时间限制，但答过的试题不能返回修改。
              </div>
            </el-checkbox-group>
          </div>
        </el-card>
        <el-card v-if="currentIndex !== 0">
          <div slot="header" class="clearfix">
            <span class="title">答题控制</span>
          </div>
          <div>
            <el-checkbox-group v-model="checkAnswerList" class="testTime" @change="changeAnswer">
              <div class="checkItem">
                <div><el-checkbox label="答题次数限制"></el-checkbox></div>
                <div>
                  <el-input
                    v-model="form.answerNum"
                    class="input-width"
                    clearable
                    :disabled="answerNum"
                  ></el-input>
                </div>
                <div class="icon">次</div>
              </div>
              <div class="text texts">
                同一个账号对考试只能作答的次数，默认只能作答一次
              </div>
              <el-checkbox label="记录答题时的IP"></el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="keepBtn">
            <el-button type="primary" @click="keepCustom">
              保存
            </el-button>
          </div>
        </el-card> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import testTime from './components/testTime'
export default {
  components: {
    testTime,
  },
  data() {
    return {
      headerData: ['试题设置', '考试设置'],
      currentIndex: 0,
      form: {},
      checkList: [],
      checkTimeList: [],
      checkAnswerList: [],
      timeData: [
        { lable: '考试限时', status: true },
        { lable: '开始时间', status: true },
        { lable: '结束时间', status: true },
        { lable: '单体限制', status: true },
      ],
      answerNum: true,
      navIndex: this.$route.query.navIndex ? this.$route.query.navIndex : '',
    }
  },
  created() {
    if (this.navIndex !== '') {
      this.currentIndex = Number(this.navIndex)
    }
  },
  methods: {
    changeNav(i) {
      this.currentIndex = i
    },
    handleCheckTimeChange() {
      this.timeData.map(v => {
        v.status = true
        this.checkTimeList.map(e => {
          if (v.lable == e) {
            v.status = false
          }
        })
      })
    },
    changeAnswer() {
      this.answerNum = true
      this.checkAnswerList.map(v => {
        if (v == '答题次数限制') {
          this.answerNum = false
        }
      })
    },
    keepCustom() {},
    handleSubmit() {},
  },
}
</script>

<style scoped lang="scss">
.customSetting {
  .headerNav {
    height: 100%;
  }
}
.setting {
  cursor: pointer;
  font-size: 16px;
  .setttingItem {
    padding-left: 20px;
    line-height: 50px;
    position: relative;
    .space {
      position: absolute;
      left: 0;
      top: 13px;
      width: 4px;
      height: 24px;
      background-color: #ff7b33;
      display: none;
    }
  }
  .active {
    color: #ff7b33 !important;
    .space {
      display: block !important;
    }
  }
}
.input-width {
  width: 102px;
  height: 40px;
}
.templateBtn {
  margin-top: 12px;
}
.span-box > i {
  margin: 0 5px;
}
.pass {
  margin-top: 24px;
  .passLine {
    margin-right: 13px;
  }
}
.writeNews {
  margin-left: 10px;
  width: 230px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .header {
    display: flex;
    line-height: 40px;
    padding: 0 14px;
    border-bottom: 1px solid #e2e2e2;
    justify-content: space-between;
    .custom {
      color: #ff7b33;
      cursor: pointer;
    }
  }
  .checkList {
    padding: 20px;
  }
}
.text {
  line-height: 20px;
  font-size: 12px;
  margin-left: 100px;
  color: #909399;
}
.texts {
  margin-left: 128px;
}
.testTime {
  margin-left: 10px;
  .checkItem {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    .input-width,
    .date {
      margin-left: 16px;
      margin-right: 10px;
    }
    .icon {
      font-size: 14px;
      i {
        color: #b8b8b8;
      }
    }
  }
}
.keepBtn {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
</style>
